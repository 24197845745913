export default {
  name: "ListTLoanCollectabilityReport",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      this.property.filterDto.officeCode;
      return getSession;
    },
  },
  data() {
    return {
      property: {
        animation: {
          addLoanCollectability: {
            isLoading: false,
          },
          checkingAccount: {
            isLoading: false,
          },
        },
        filterDto: {
          loanCollectabilityId: "",
          accountNumber: "",
          officeCode: "",
          cifIdName: "",
          collectabilityStatusId: "",
        },
      },
      dataForm: {
        searchLoanData: {
          loanAccountNumber: "",
          cifIdName: "",
        },
        loanAccountId: 0,
        loanCollectabilityId: "",
        accountName: "",
        accountCabang: "",
        loanName: "",
        nominal: 0,
        endDate: "",
        collectabilityNow: "",
        description: "",
      },
      session: {
        loanData: {
          loanAccountNumber: "",
          cifIdName: "",
        },
      },
      options: {
        cabang: [],
        kolektabilitas: [],
      },
      table: {
        data: {
          loanCollectability: [],
          listAccount: [],
        },
        pagination: {
          loanCollectability: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
          loanAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
      },
    };
  },
  methods: {
    async helperGetLoanCollectabilityDataTable() {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "t-collectability/simple-list",
          payload: {
            accountNumber: "",
            cifIdName: "",
            officeCode: "",
            collectabilityStatusId: "",
            page: 0,
            size: this.table.pagination.loanCollectability.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollectability = resp.data.data.content;
            this.table.pagination.loanCollectability.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollectability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanCollectability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.table.pagination.loanCollectability.downloading = false;
        }, timeout);
      }
    },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })
        .then((response) => {
          Promise.all(
            (this.options.cabang = [{ value: "", text: "-- Pilih --" }]),
            response.data.data.content.map((index) => {
              const value = index.officeCode;
              const text = index.officeName;

              this.options.cabang.push({ text, value });
            })
          ).then(() => {});
        });
    },
    async changePaginationLoanCollectabilityDraft(event) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "t-collectability/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            officeCode: this.property.filterDto.officeCode,
            collectabilityStatusId: this.property.filterDto
              .collectabilityStatusId,
            page: event - 1,
            size: this.table.pagination.loanCollectability.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollectability = resp.data.data.content;
            this.table.pagination.loanCollectability.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetLoanCollectability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleFailedGetLoanCollectability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.table.pagination.loanCollectability.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetLoanCollectability(resp) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.rows = 0;
      this.table.pagination.loanCollectability.message = resp.data.message;
    },
    handleCatchErrorLoanCollectability(error) {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.rows = 0;
      this.table.pagination.loanCollectability.currentPage = 1;

      this.table.pagination.loanCollectability.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async findLoanCollectabiliy() {
      this.table.data.loanCollectability = [];
      this.table.pagination.loanCollectability.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "t-collectability/simple-list",
          payload: {
            accountNumber: this.property.filterDto.accountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            officeCode: this.property.filterDto.officeCode,
            collectabilityStatusId: this.property.filterDto
              .collectabilityStatusId,
            page: 0,
            size: this.table.pagination.loanCollectability.perPage,
          },
        });
        setTimeout(() => {
          // console.log(resp.data)
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanCollectability = resp.data.data.content;
            this.table.pagination.loanCollectability.rows =
              resp.data.data.totalElements;
            this.table.pagination.loanCollectability.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.handleFailedGetLoanCollectability(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleCatchErrorLoanCollectability(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.table.pagination.loanCollectability.downloading = false;
        }, timeout + 500);
      }
    },
    getReferenceLoanCollectabilityStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-status",
        })
        .then((resp) => {
          this.options.kolektabilitas = [
            { value: "", text: "-- Semua Kategori --" },
          ];
          resp.data.data.map((i) => {
            const value = i.collectabilityStatusId;
            const text = i.collectabilityStatusName;
            this.options.kolektabilitas.push({ text, value });
          });
        });
    },
  },
  mounted() {
    this.helperGetLoanCollectabilityDataTable();
    this.helperGetOfficeDataForReference();
    this.getReferenceLoanCollectabilityStatus();
  },
};
